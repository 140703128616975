#login-screen {
  width: 60%;
  min-height: 70vh;
  margin: 0 auto;
}

.login-logo {
  background-image: url("../../logo.svg");
  background-size: 400px;
  width: 400px;
  height: 52.87px;
  margin: 10vh auto 0;
}

.login-header {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  margin: 24px;
}

.email-input, .password-input, .remember-me-input, .submit-button  {
  width: 410px;
  margin: 0 auto;
}

#login-screen input[type="text"], #login-screen input[type="password"] {
  width: 440px;
  height: 56px;
  padding: 8px 12px;
}

#login-screen .btn-flat {
  width: 440px;
  height: 48px;
  border-radius: 0.25rem;
}

.email-input input {
  border: 1px solid #9CA3AF;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom: 0;
}

.password-input input {
  border: 1px solid #9CA3AF;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.remember-me-input {
  padding-top: 24px;
}

.remember-me-input .remember-me{
  margin-right: 8px;
}

.remember-me-input a {
  color: #000000;
}

.submit-button {
  margin-top: 24px;
}

/* Login screen overrides */

.bounceback-app.no-auth {
  background-color: #ffffff !important;
}

.bounceback-app.no-auth .sidebar {
  display: none;
}

.bounceback-app.no-auth .content-container {
  width: 100% !important;
}