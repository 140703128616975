@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

/** Figma Design System **/
.ds-complement-500 {
  color: #F89F64;
}

/** Global **/
.bounceback-app {
  background-color: #E9F5F8;
  min-height: 100vh;
  padding: 24px 32px;
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bounceback-app .input-group-text {
  background-color: #ffffff;
}

.bounceback-app a {
  color: #239CB6;
  text-decoration: none;
}

.form-floating {
  margin-bottom: 8px;
}

.r-actions {
  width: 25%;
}

.r-actions a {
  margin-right: 1em;
}

.table thead th {
  color: #6B7280;
  font-weight: normal;
  text-transform: uppercase;
}

.table td img {
  margin-right: 0.5rem;
}

.drop-area {
  padding: 1em;
  border: 2px dotted #D1D5DB;
  border-radius: 0.75rem;
}

.drop-area img, .drop-area p {
  display: block;
  margin: 0 auto;
}

.drop-area p, .drop-area aside {
  margin-top: 1em;
  text-align: center;
}

/** Header **/
.user-widget {
  padding-bottom: 28px;
  margin-bottom: 50px;
  border-bottom: 2px solid #E5E7EB; 
}

.user-widget #globalSearch {
  border-right: none;
}

.user-widget #globalSearch, .user-widget .input-group-text {
  background: #F3F4F6;
}

.user-widget a {
  color: var(--bs-body-color);
}

.user-widget .profile-image {
  width: 32px;
  height: 32px;
  margin-left: 32px;
  border-radius: 4px;
}

.user-widget h1 {
  color: #6B7280;
  font-weight: 700;
  font-size: 30px;
  line-height: 34px;
  margin-top: 12px;
}

.user-widget strong {
  color: #121212;
}

#user-profile-widget #user-profile-dropdown {
  border: none;
  background: inherit;
  color: inherit;
}

#user-profile-widget #user-profile-dropdown:focus {
  box-shadow: 0 0 0 0.2rem rgb(241 159 100 / 50%);
}

.login-error {
  width: 410px;
  margin: 0 auto;
}

.login-error small {
  display: block;
  padding-top: 24px;
  color: red;
}

/** Sidebar **/
.logo {
  background-image: url("logo.svg");
  background-size: 208.8px;
  width: 208.8px;
  height: 27.6px;
}

.sidebar a {
  display: block;
}

.admin-sidebar {
  padding: 32px 32px 0 0;
}

.admin-sidebar .btn-flat {
  width: 220px;
  margin-bottom: 8px;
  background-color: #C8E6ED;
  color: #239CB6 !important;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
}

.admin-sidebar .active .btn-flat {
  background-color: #F89F64;
  color: white !important;
}

.sidebar .rstm-tree-item-group {
  border-top: none;
}

.sidebar .rstm-tree-item {
  width: 220px;
  margin-bottom: 8px;
  padding: 0.5rem;
  border-radius: 0.25rem;
  background-color: #C8E6ED;
  color: #239CB6;
  text-align: left;
}

.sidebar .rstm-tree-item-group .rstm-tree-item--active {
  background-color: #F89F64 !important;
  color: #ffffff !important;
  font-weight: 600;
  font-size: 16px;
}

.sidebar .rstm-toggle-icon {
  float: right;
}

.sidebar .rstm-tree-item-level1, .sidebar .rstm-tree-item-level2,
.sidebar .rstm-tree-item-level3, .sidebar .rstm-tree-item-level4, .sidebar .rstm-tree-item-level5 {
  font-weight: 600;
  font-size: 16px;
  color: #ffffff !important;
  background-color: #65BACC !important;
}

.sidebar .rstm-tree-item-level0 {
  padding-left: 0.75rem !important;
}

.sidebar .rstm-tree-item-level1 {
  padding-left: 1.25rem !important;
}

.sidebar .rstm-tree-item-level2 {
  padding-left: 1.75rem !important;
}

.sidebar .rstm-tree-item-level3 {
  padding-left: 2.25rem !important;
}

.sidebar .rstm-tree-item-level4 {
  padding-left: 2.75rem !important;
}

.sidebar .rstm-tree-item-level5 {
  padding-left: 3.25rem !important;
}

@media screen and (max-width: 1366px) {
  .admin-sidebar .btn-flat, .sidebar .rstm-tree-item {
    width: 180px;
  }
}

@media screen and (max-width: 980px) {
  .admin-sidebar .btn-flat, .sidebar .rstm-tree-item {
    width: 230px;
  }
}

/** Main content **/
.content {
  background-color: #ffffff;
  border-radius: 48px;
  padding: 32px;
}

#actions {
  margin-bottom: 16px;
}

.content .btn-flat, .modal-dialog .btn-flat {
  background-color: #F89F64;
  color: #ffffff;
}

.content .btn-flat:hover, .modal-dialog .btn-flat:hover {
  background-color: #f3a774;
  color: #ffffff;
}

.modal-dialog .rbt.form-control input {
  border: none;
}

.content .table {
  border: 1px solid #E5E7EB;
  border-radius: 0.5rem;
  border-collapse: separate !important;
}

.content .table thead {
  background: #F9FAFB;
}

.content .table>:not(:first-child) {
  border-top: none;
}

.content .table>:not(caption)>tr:last-child>* {
  border-bottom: 0;
}

.content .table tbody tr td strong {
  font-weight: 600;
}

.content h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 34px;
}

.knowledge-check-edit {
  margin: 0 96px;
}

.kc-button {
  display: block !important;
  width: 40%;
  margin: 0 auto;
}

.kc-button-back {
  margin-bottom: 36px;
  background-color: #ffffff !important;
  color: #121212 !important;
  border: 1px solid #121212 !important;
}

.knowledge-check-edit .dropdown {
  margin-bottom: 1em;
}

.knowledge-check-edit .box {
  margin-top: 1em;
}

.knowledge-check-edit .dropdown-basic {
  background-color: #C8E6ED !important;
  color: #155E6D !important;
  border: none;
  font-size: 14px;
  font-weight: 400;
}

.bg-transparent{
  background: transparent !important;
}

.no-data img {
  margin: 200px auto 0 auto;
  display: block;
}

.no-data-actions img {
  width: 190.08px;
  height: 248.37px;
  position: absolute;
  top: 50.5px;
  right: 213.5px;
}

.no-data-actions strong, .no-data-actions span {
  display: block;
  text-align: center;
  margin-top: 4px;
}

.no-data-actions strong {
  margin-top: 32px;
}

.no-data-actions span {
  margin-bottom: 200px;
}

.no-data-actions .btn {
  width: 160px;
  display: block;
  margin: 24px auto 200px auto;
}


/*Checkbox*/
input[type="checkbox"] {
  visibility: hidden;
  position: relative;

  margin-left: 5px;
  width: 20px;
  height: 20px;
}

input[type="checkbox"]:before {
  content: "";
  visibility: visible;
  content: "";
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #b4b4b4;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: -0.2em;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}

input[type="checkbox"]:checked:before {
  background-color: #239cb6;
  box-shadow: inset 0 0 0 4px #ffffff;
  border: 1.5px solid #239cb6;
  box-sizing: border-box;
  border-radius: 6px;
}

.separate-r > input{
  margin-right: 15px;
}